import React from "react"
import Particles from "react-particles-js"
import "../css/main.css"

const particle3 = () => {
  return (
    <div id="particles-js">
      <Particles
        params={{
          particles: {
            number: {
              value: 150,
              density: {
                enable: false,
                value_area: 1200,
              },
            },
            color: {
              value: "#e22cca",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#e22cca",
              },
              polygon: {
                nb_sides: 3,
              },
              image: {
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.1,
              random: true,
              anim: {
                enable: false,
                speed: 2,
                opacity_min: 0.06492189085007105,
                sync: false,
              },
            },
            size: {
              value: 2,
              random: true,
              anim: {
                enable: true,
                speed: 10.5,
                size_min: 0.8,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 200,
              color: "#4eeb60",
              opacity: 1,
              width: 0,
            },
            move: {
              enable: true,
              speed: 1.5,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: true,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "window",
            events: {
              onhover: {
                enable: false,
                mode: "bubble",
              },
              onclick: {
                enable: false,
                mode: "bubble",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 227.77222777222772,
                line_linked: {
                  opacity: 0.671792796438689,
                },
              },
              bubble: {
                distance: 90,
                size: 2.1,
                duration: 0.15984015984015984,
                opacity: 1,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  )
}
export default particle3
