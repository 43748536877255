import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Particle3 from "../components/Particle3"
import "../css/main.css"
import "../css/resume.css"
import SEO from "../components/SEO"
import { FaFileAlt, FaLinkedin, FaTwitter, FaGithub } from "react-icons/fa"

const resume = () => {
  return (
    <Layout>
      <SEO title="Resume" description="This is resume page of Arpit Somani" />
      <Particle3></Particle3>
      <h6>s</h6>
      <div className="dtg noSelect">
        <div className="head">
          <h1>Arpit Somani</h1>

          <h4 className="obj">Python Programmer | Full Stack Developer | Machine Learning Enthusiast</h4>

          <div className="tradi">
            <h3>The Traditional Resume</h3>
            <a
              href="https://drive.google.com/file/d/1WRtEDvq98KevJQ9BUB0zOZgrfM03nTe-/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFileAlt className="resume-icon"></FaFileAlt>
            </a>
            <p>( Downloadable Document )</p>
          </div>
          <h4>Contact Info :</h4>
          <p>Email: arpit.somanias8@gmail.com</p>

          <a
            href="https://www.linkedin.com/in/arpit-somani-83267b131"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="resume-icon"></FaLinkedin>
          </a>

          <a
            href="https://github.com/arpitsomani8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="resume-icon"></FaGithub>
          </a>

          <a
            href="https://twitter.com/arpit_somani8/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="resume-icon"></FaTwitter>
          </a>
        </div>
      </div>

      <div className="ropi noSelect">
        <div className="res">
          <div className="resu">
            <Link to="#soq">
              <p className="text-col">#Summary of Qualifications</p>
            </Link>
            <Link to="#work">
              <p className="text-col">#Work Experience</p>
            </Link>
            <Link to="#skill">
              <p className="text-col">#Skill Set</p>
            </Link>
            <Link to="#education">
              <p className="text-col">#Education</p>
            </Link>
            <Link to="#project">
              <p className="text-col">#Project Showcase</p>
            </Link>
            <Link to="#accomp">
              <p className="text-col">#Accomplishment</p>
            </Link>
            <Link to="#certification">
              <p className="text-col">#Certification</p>
            </Link>
            <Link to="#extra">
              <p className="text-col">#Extra Curricular Activities</p>
            </Link>
            <Link to="#interest">
              <p className="text-col">#Interests & Hobbies</p>
            </Link>
          </div>
        </div>

        <div className="resume-page">
          <div className="first-box gty" id="soq">
            <h2>Summary of Qualifications</h2>
            <p>
              <span role="img" aria-label="Solid">
                💪
              </span>{" "}
              Solid coding skills in Python
            </p>

            <p>
              <span role="img" aria-label="Spark">
                📊
              </span>{" "}
              Data Analysis and visualization
            </p>
            <p>
              <span role="img" aria-label="Spark">
                📈
              </span>{" "}
              Machine Learning Algorithms
            </p>
            <p>
              <span role="img" aria-label="Spark">
                ✨
              </span>{" "}
              Sound knowledge of both Front and Back End-Development
            </p>
          </div>

          <div className="first-box education" id="work">
            <h2>Work Experience</h2>
            <p>
              <a
                href="https://blobcity.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>BlobCity</h3>
              </a>
              <h4>AI Intern</h4>
              <h5>26-07-2021 to 24-09-2021<br/>
(Work from home Internship)<br/><br/>
Making Models on various AI problem statements
that can help the world save time in building their
AI projects and making them open-source & freely
available for the world to use.
</h5>
              <br />

              <a
                href="https://www.thesparksfoundationsingapore.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>The Sparks Foundation</h3>
              </a>
              <h4>Data Science & Business Analytics</h4>
              <h5>26-07-2021 to 24-08-2021<br/>
(Work from home Internship)<br/><br/>
Build and tune machine learning models using
Python and scikit-learn on a given dataset.</h5>
              <br />

              <a
                href="https://www.sheltown.in/YouOwnIt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Sheltown</h3>
              </a>
              <h4>Front End Developer
             </h4>
              <h5>11-11-2020 to 30-12-2020<br/>
                (Work from home Internship)<br/><br/>Developing new and responsive flavour for the
company's official website pages. Html, CSS,
Bootstrap,and JavaScript are used for this purpose.</h5>
            </p>
          </div>

          <div className="first-box" id="skill">
            <h2>Skill Set</h2>
            <h3>Finest Programmimg language:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>Python</span>
              </button>
            </div>
            <br />
            <h3> also familiar with:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>C</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>C++</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Java</span>
              </button>
            </div>

            <br />

            <h3>Web Developing/ Scripting:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>HTML5</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>CSS3</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>JavaScript</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Bootstrap</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>React</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>GatsbyJS</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>NodeJS</span>
              </button>

            </div>
            <br />
            <h3>Data Analysis and visualization</h3>
            <br />
            <h3>Machine Learning Algorithms</h3>
            <br />
            <h3>Packages:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>Scikit-learn</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Numpy</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Pandas</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Matplotlib</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Seaborn</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span> SciPy</span>
              </button>
            </div>
            <br />
            <h3>DataBase:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>MongoDB</span>
              </button>

              <button type="button" className="resume-btton" disabled>
                <span>MySQL</span>
              </button>
            </div>
            <br />
            <h3>Framework:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>Django</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Flask</span>
              </button>
            </div>
            <br />
            <h3>Cloud:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>AWS (EC2 Instance)</span>
              </button>

            </div>
            <br />
            <h3>Tools:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>Git</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>NPM</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Yarn</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Netlify</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Firebase</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Heroku</span>
              </button>
            </div>

            <h3>IDEs:</h3>
            <div className="resume-button-box">
              <button type="button" className="resume-btton" disabled>
                <span>Spyder</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Jupyter Notebook</span>
              </button>
              <button type="button" className="resume-btton" disabled>
                <span>Visual Studio Code</span>
              </button>
            </div>
          </div>

          <div className="first-box education" id="education">
            <h2>Education</h2>
            <p>
              <a
                href="https://www.skit.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Swami Keshwananad Institute of Technology, Jaipur</h3>
              </a>
              <h4>B.Tech (Computer Science Engineering)</h4>
              <h5>2018-Present</h5>
              <br />

              <a
                href="https://bhilwara.kvs.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Kendriya Vidyalaya Sangathan, Bhilwara</h3>
              </a>
              <h4>High School</h4>
              <h5>2005-2017</h5>
            </p>
          </div>

          <div className="first-box hgt" id="project">
            <h2>Project Showcase</h2>
            <h3>Machine Learning Projects</h3>
            <div className="resume-proj">
              <Link to="/ml_proj1/">
                <div className="box555 box61">
                  <h4>Salary Prediction</h4>
                </div>
              </Link>

              <Link to="/ml_proj2/">
                <div className="box555 box62">
                  <h4>Predict Car Price</h4>
                </div>
              </Link>
            </div>
            <div className="csd">
              <Link to="/ml_project">
                <div type="button" className="poi">
                  <span>More ML Projects</span>
                </div>
              </Link>
            </div>


            <h3>Python Projects</h3>
            <div className="resume-proj">
              <Link to="/py_proj34/">
                <div className="box555 box51">
                  <h4> Weather App</h4>
                </div>
              </Link>
              <Link to="/py_proj1/">
                <div className="box555 box50">
                  <h4>Egg Catcher Game</h4>
                </div>
              </Link>

            
            </div>
            <div className="csd">
              <Link to="/pythonprojectcard">
                <div type="button" className="poi">
                  <span>More Python Projects</span>
                </div>
              </Link>
            </div>

            <h3>Web-Dev Projects</h3>

            <div className="resume-proj">
              <Link to="/web_proj1/">
                <div className="box555 box52 rc">
                  <h4>Ristotante Confusion</h4>
                </div>
              </Link>
              <Link to="/web_proj3/">
                <div className="box555 box53">
                  <h4>Coffee Shop</h4>
                </div>
              </Link>
            </div>

            <div className="csd">
              <Link to="/webprojectcard">
                <div type="button" className="poi">
                  <span>More web-dev Projects</span>
                </div>
              </Link>
            </div>
          </div>

          <div className="first-box" id="accomp">
            <h2>Accomplishment</h2>
            <h3>Data Structures And Algorithms (Basics) with Python</h3>
            <h5>August 2020</h5>
            <p>
              I have heard a lot about DSA, since from when I first step into
              CS/IT field.
              <br />
              So, keeping in mind how important is this, I have done it with my
              favourite programming language: Python
            </p>
          </div>
          <div className="first-box" id="certification">
            <h2>Certification</h2>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              45 days online live training on Machine Learning and Data Science with Python from Goeduhub Technologies <h5> Jul 2021</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Neural Networks and Deep Learning from
              Coursera <h5> Nov 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Problem Solving (Basic) certificate from
              HackerRank <h5>Oct 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Python for Data Science and Machine
              Learning Bootcamp from Udemy <h5> Sep 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Python(Intermediate) certificate from
              HackerRank <h5> Sep 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Front-End Web Development with React
              from Coursera <h5> Jun 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Front-End Web UI Frameworks and Tools:
              Bootstrap 4 from Coursera <h5> May 2020</h5>
            </p>
            <p>
              <span role="img" aria-label="Certificate">
                📜
              </span>
              Python certification from NPTEL <h5> Dec 2019</h5>
            </p>
          </div>

          <div className="first-box gft" id="extra">
            <h2>Extra Curricular Activities</h2>
            <p>
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Participated in Amazon ML Challenge
2021, on Hackerearth

              <br />
              <br />
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Open Source contribution in
Hacktoberfest 2020

              <br />
              <br />
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Contribution as a Moderator in all 3
Days of ‘Boost Your Skill’ online
technology enhancement program by
Coding Nurture.

            </p>
          </div>
          <br/><br/>

          <div className="first-box gft" id="interest">
            <h2>Interests & Hobbies</h2>
            <p>
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Solving compititive coding questions using python on various
              well-known online platforms
              <br />
              <br />
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              I write a{" "}
          <Link className="gop" to="/blog/">
            Blog
          </Link>{" "}, I share what I know, as well as what I am learning
              <br />
              <br />
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Always ready to expand my skills with new tech and projects
              involving python
              <br />
              <br />
              <span role="img" aria-label="Sparkel Star">
                🌟
              </span>
              Love to play guitar
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default resume
